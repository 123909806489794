import React from 'react';
import { graphql, Link } from 'gatsby';
import { get } from 'lodash';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {truncateDescription} from '../utils/helpers';
import Layout from '@components/layout';
import SEO from '@components/seo';
import WorkmarkLogo from '@images/wilderandweathered-wordmark-white.svg';

import HeroMp4 from '@videos/hero-banner-720.mp4';
import HeroWebm from '@videos/hero-banner-720.webm';

import '@scss/main.scss';

const getPrice = (product) => {
  const price = get(product, 'variants[0]price', null);
  return price ? `$${price}`: '';
}

export default function IndexPage({ data }) {
  // console.log(data);
  const today = new Date();
  const hour = today.getHours();
  const isSunlight = (hour >= 6 && hour <= 19);
  const bottomBlurb = get(data, "homePage.homePageFields.bottomBlurb", null);
  const featuredArticle = get(data, 'featuredArticle', {});
  const featuredProduct = get(data, 'featuredProduct.edges[0].node.products[0]', {});
  const featuredArticleImage = getImage(featuredArticle.featuredImage.node.localFile);
  const featuredProductImage = getImage(featuredProduct.images[0].localFile);
  return (
    <Layout baseRoute="home" homeBottomBlurb={bottomBlurb}>
      <SEO title="Our creative space by Wilder & Weathered" />
      <div id="hero-wrapper" className={`${isSunlight && "day"}`}>
        <div className="home-hero">
          <div id="video-bg-wrapper">
            <video
              autoPlay
              playsInline
              muted
              loop
              id="video-bg"
            >
              <source src={HeroMp4} type="video/mp4" />
              <source src={HeroWebm} type="video/webm" />
            </video>
            <div className="overlay"> </div>
          </div>
          <div className="wordmark white">
            <WorkmarkLogo />
          </div>
          <div
            className="tagline"
            dangerouslySetInnerHTML={{
              __html: data.homePage.homePageFields.heroTagline,
            }}
          />
        </div>
      </div>
      <div
        id="little-blurb"
        className="blurb"
        dangerouslySetInnerHTML={{
          __html: data.homePage.homePageFields.topBlurb,
        }}
      />
      <div className="featured-items-wrapper">
        {featuredProduct && (
          <div className="featuredBlock">
            <div className="heading">Featured Product</div>
            <Link
              to={`/shop/product/${featuredProduct.handle}`}
              className="title"
              alt={featuredProduct.title}
            >
              <GatsbyImage
                layout="constrained"
                image={featuredProductImage}
                alt={featuredProduct.title}
              />
            </Link>
            <Link
              to={`/shop/product/${featuredProduct.handle}`}
              className="title"
              alt={featuredProduct.title}
            >
              {featuredProduct.title}{" "}
              <span className="price">{getPrice(featuredProduct)}</span>
            </Link>
            <br />
            <Link to={`/shop`} className="button-block" alt="View Our Shop">
              View Our Shop
            </Link>
          </div>
        )}
        {featuredArticle && (
          <div className="featuredBlock">
            <div className="heading">Featured Article</div>
            <Link
              to={`${featuredArticle.uri}`}
              className="title"
              alt={featuredArticle.title}
            >
              <GatsbyImage
                layout="constrained"
                image={featuredArticleImage}
                alt={featuredArticle.title}
              />
            </Link>
            <Link
              to={`${featuredArticle.uri}`}
              className="title"
              alt={featuredArticle.title}
            >
              {featuredArticle.title}
            </Link>
            <div
              className="excerpt"
              dangerouslySetInnerHTML={{
                __html: truncateDescription(featuredArticle.excerpt),
              }}
            />
            <Link
              to={`/blog`}
              className="button-block"
              alt="Check Out Our Blog"
            >
              Check Out Our Blog
            </Link>
          </div>
        )}
      </div>
      <div
        className="homePageBottomBlurb"
        dangerouslySetInnerHTML={{
          __html: bottomBlurb,
        }}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allWpPage {
      edges {
        node {
          id
          title
          slug
          uri
        }
      }
    }
    featuredProduct: allShopifyCollection(
      filter: { title: { eq: "Featured" } }
      limit: 1
    ) {
      edges {
        node {
          products {
            handle
            descriptionHtml
            description
            title
            variants {
              price
            }
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: CONTAIN }
                    backgroundColor: "transparent"
                    layout: CONSTRAINED
                    aspectRatio: 1.78
                  )
                }
              }
            }
          }
        }
      }
    }
    homePage: wpPage(databaseId: { eq: 5 }) {
      homePageFields {
        bottomBlurb
        fieldGroupName
        heroTagline
        topBlurb
      }
    }
    featuredArticle: wpPost(isSticky: { eq: true }) {
      id
      slug
      excerpt
      featuredImage {
        node {
          altText
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER }
                backgroundColor: "transparent"
                layout: CONSTRAINED
                aspectRatio: 1.78
              )
            }
          }
        }
      }
      uri
      title
    }
  }
`;
